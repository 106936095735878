// Export a object to allow cypress to mock

export const redirectTo = (url: string) => {
  window.location.href = url;
};

type Map = { [key: string]: string };

export const getQueryStringObject = (url?: string): Map => {
  if (!url) {
    return {};
  }
  const urlParts = url.split("?");
  if (urlParts.length !== 2) {
    return {};
  }
  const result: Map = {};
  const searchParams = new URLSearchParams(urlParts[1]);
  searchParams.forEach((value, key) => {
    result[key] = value;
  });
  return result;
};

export default {
  redirectTo,
  getQueryStringObject,
};
