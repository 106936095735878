import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";
import {
  DocumentsFilter,
  getAppointmentAvailability,
  getAppointmentPackage,
  getAppointmentType,
  getAppointmentTypeStaff,
  getCalendar,
  getClientSettings,
  getClientTax,
  getClientTaxes,
  getUnsignedDocuments,
  getLinkedAccounts,
  getPaymentMethods,
  getStripeClientSecret,
  getCalendarByRange,
  getUserCountryByIp,
} from "./api";
import { useAppContext } from "./contexts/AppContext";
import { useAppQuery, UseAppQueryOptions } from "./utils/hooks";
import { filterValidClasses } from "./screens/plans/Booking/Booking.utils";
import { PlanFragment } from "./graphql/graphql-generated.types";

export const useGetAppointmentPackage = (
  appointmentPackageId: string,
  options?: UseAppQueryOptions<AppointmentPackage>,
) => {
  const { client } = useAppContext();
  return useAppQuery(
    ["appointment-package", client.uuid, appointmentPackageId],
    () => getAppointmentPackage(client.uuid, appointmentPackageId),
    options,
  );
};

export const useGetAppointmentType = (
  appointmentTypeId: string,
  options?: UseAppQueryOptions<AppointmentType>,
) => {
  const { client } = useAppContext();
  return useAppQuery(
    ["appointment-type", client.uuid, appointmentTypeId],
    () => getAppointmentType(client.uuid, appointmentTypeId),
    options,
  );
};

export const useGetLinkedAccounts = (
  options?: UseAppQueryOptions<Participant[]>,
) => {
  const { user } = useAppContext();
  return useAppQuery(
    ["linked-accounts", user?.userUuid],
    () => getLinkedAccounts(user?.userUuid!),
    options,
  );
};

export const useGetPaymentMethods = (
  userUuid?: string,
  options?: UseAppQueryOptions<PaymentMethod[]>,
) => {
  const { user } = useAppContext();
  const id = userUuid || user?.userUuid;
  return useAppQuery(
    ["payment-methods", id],
    () => getPaymentMethods(id!),
    options,
  );
};

export const useUpdateGetPaymentMethods = (participantUuid?: string) => {
  const queryClient = useQueryClient();
  return useCallback(
    (callback: (data?: PaymentMethod[]) => void) => {
      if (!participantUuid) {
        return;
      }
      const keys = ["payment-methods", participantUuid];
      const data = queryClient.getQueryData<PaymentMethod[]>(keys);
      const newData = callback(data);
      queryClient.setQueryData(keys, newData);
    },
    [queryClient, participantUuid],
  );
};

export const useGetStripeClientSecret = (
  clientUuid: string,
  userUuid: string,
  options?: UseAppQueryOptions<StripeOptions>,
) => {
  return useAppQuery(
    ["stripe-secret", clientUuid, userUuid],
    () => getStripeClientSecret(clientUuid, userUuid),
    options,
  );
};

export const useGetAppointmentTypeStaff = (
  appointmentTypeId: string,
  options?: UseAppQueryOptions<AppointmentTypeStaff[]>,
) => {
  const { client } = useAppContext();
  return useAppQuery(
    ["appointment-type-staff", client.uuid, appointmentTypeId],
    () => getAppointmentTypeStaff(client.uuid, appointmentTypeId),
    options,
  );
};

export const useGetAppointmentAvailability = (
  params: {
    appointmentTypeId: string | number;
    date: string;
    staffId: string;
    timeZone: string;
  },
  options?: UseAppQueryOptions<AvailableSlot[]>,
) => {
  const { client } = useAppContext();
  return useAppQuery(
    [
      "appointment-availability",
      client.uuid,
      params.appointmentTypeId,
      params.date,
      params.staffId,
      params.timeZone,
    ],
    () =>
      getAppointmentAvailability({
        ...params,
        clientUuid: client.uuid,
      }),
    options,
  );
};

export const useGetCalendar = (
  timestamp: number,
  plan?: PlanFragment,
  timezone?: string,
  options?: UseAppQueryOptions<CalendarData[]>,
) => {
  const momentValue = moment(timestamp);
  const doy = momentValue.utc().dayOfYear();
  const year = momentValue.utc().year();

  const queryResult = useAppQuery<CalendarData[]>(
    ["calendar", doy, year],
    async () => {
      const calendar = await getCalendar(doy, year);

      if (!plan) {
        return calendar.data;
      }

      const filteredCalendarData = filterValidClasses(
        plan,
        calendar.data,
        timezone!,
      );
      return filteredCalendarData;
    },
    options,
  );

  return queryResult;
};

export const useGetCalendarByRange = (
  start: number,
  end: number,
  plan: PlanFragment,
  timezone: string,
  options?: UseAppQueryOptions<CalendarData[]>,
) => {
  const queryResult = useAppQuery<CalendarData[]>(
    ["calendar-by-range", plan.uuid],
    async () => {
      if (!plan?.calendarItemTypes?.length) {
        const calendar = await getCalendarByRange(start, end);
        const unfilteredCalendarData = calendar.data;
        return filterValidClasses(plan, unfilteredCalendarData, timezone);
      }

      const calendarDataPromises = plan.calendarItemTypes.map(
        async (itemType) => {
          const calendar = await getCalendarByRange(start, end, itemType);
          const unfilteredCalendarData = calendar.data;
          return filterValidClasses(plan, unfilteredCalendarData, timezone);
        },
      );

      const calendarData = await Promise.all(calendarDataPromises);
      return calendarData.flatMap((data) => data);
    },
    options,
  );

  return queryResult;
};

export const useGetUnsignedDocuments = (
  userUuid?: string,
  filters?: DocumentsFilter,
  options?: UseAppQueryOptions<GetDocumentsResult>,
) => {
  return useAppQuery(
    ["documents", userUuid],
    () => getUnsignedDocuments(userUuid, filters),
    options,
  );
};

export const useUpdateGetDocuments = (
  planUuid: string | undefined,
  discountSlug: string | undefined,
) => {
  const queryClient = useQueryClient();
  const { client, user } = useAppContext();
  return useCallback(
    (callback: (data?: GetDocumentsResult) => void) => {
      const keys = [
        "documents",
        client.uuid,
        user!.userUuid,
        planUuid,
        discountSlug,
      ];
      const data = queryClient.getQueryData<GetDocumentsResult>(keys);
      const newData = callback(data);
      queryClient.setQueryData(keys, newData);
    },
    [client.uuid, discountSlug, planUuid, queryClient, user],
  );
};

export const useGetClientSettings = (
  clientUuid: string,
  type: string,
  name: string,
  options?: UseAppQueryOptions<ClientSetting>,
) => {
  return useAppQuery(
    ["client-settings", clientUuid, type, name],
    async () => {
      const resp = await getClientSettings(clientUuid, type, name);
      return resp?.[0] || {};
    },
    options,
  );
};

export const useGetClientTaxes = (
  clientUuid: string,
  options?: UseAppQueryOptions<ClientTax[]>,
) => {
  return useAppQuery(
    ["client-taxes", clientUuid],
    async () => await getClientTaxes(clientUuid),
    options,
  );
};

export const useGetClientTax = (
  clientUuid: string,
  taxUuid: string,
  options?: UseAppQueryOptions<ClientTax>,
) => {
  return useAppQuery(
    ["client-tax", clientUuid, taxUuid],
    async () => await getClientTax(clientUuid, taxUuid),
    options,
  );
};

export const useGetUserCountryByIp = () => {
  const [data, setData] = useState<UserCountryDataByIp>();

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await getUserCountryByIp();
      setData(response);
    };

    fetchCountries();
  }, []);

  return {
    data,
  };
};
