import { Stripe } from "@stripe/stripe-js";
import { createContext, useContext } from "react";
import { ApolloQueryResult } from "@apollo/client";
import { GetUserHasPasswordQuery } from "../graphql/graphql-generated.types";

export interface AppContextState {
  client: Client;
  user: User | null;
  ssoEnabled: boolean;
  languages: Language[];
  activeLanguage?: Language;
  baseUrl: string;
  landingPagesType: LandingPagesType;
  ip: string | null;
  today: number;
  stripe?: Stripe;
  stripeOptions?: StripeOptions;
  disableStripe?: boolean;
  subdomain: string;
  errorMessage?: string;
  clientLocale: string;
  clientPhoneCountry: string;
  userFullName?: string;
  source: string | null;
  appleStoreLink: string | null;
  googleStoreLink: string | null;
  resetPasswordUrl?: string;
  userHasPassword: boolean;
  resetAppState: VoidFunction;
  setUser: (user: User) => void;
  changeLanguage: (language: Language) => void;
  setUserUuid: (uuid?: string) => void;
  refetchStripeOptions: VoidFunction;
  logout: VoidFunction;
  gotoLogin: (email: string) => void;
  setResetPasswordUrl: (url: string) => void;
  refetchUserInfo: () => Promise<ApolloQueryResult<GetUserHasPasswordQuery>>;
}

const AppContext = createContext<AppContextState | undefined>(undefined);

export const useAppContext = () => {
  const contextValue = useContext(AppContext);
  if (!contextValue) {
    throw new Error("useAppContext must be used inside AppContext");
  }
  return contextValue;
};

export default AppContext;
