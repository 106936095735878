import { format } from "date-fns";
import { removeTimezone } from "./locale";
import { formatInTimeZone } from "date-fns-tz";

export const formatRange = (
  start?: string,
  end?: string,
  isAllDay?: boolean,
) => {
  if (!start) {
    return "";
  }

  const startDate = new Date(removeTimezone(start));
  const formattedDate = format(startDate, "EEEE, MMM dd");

  if (isAllDay || !end) {
    return formattedDate;
  }

  const startTime = format(startDate, "h:mma").toLowerCase();
  const endDate = new Date(removeTimezone(end));
  const endTime = format(endDate, "h:mma").toLowerCase();
  const bulletSeparator = "     •     ";
  return `${formattedDate}${bulletSeparator}${startTime} - ${endTime}`;
};

/**
 * Get the timezone offset in minutes of a specific timezone
 * @example getTimezoneOffset("America/Los_Angeles");  // returns 480 (8 hours)
 */
export const getTimezoneOffset = (timeZone: string) => {
  const date = new Date();
  const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
  const tzDate = new Date(date.toLocaleString("en-US", { timeZone }));
  return (utcDate.getTime() - tzDate.getTime()) / 6e4;
};

/**
 * Get the timezone offset in minutes of the local timezone
 * @example getTimezoneOffset();  // returns 180 (3 hours) for "America/Sao_Paulo"
 */
export const getLocalOffset = () => {
  return new Date().getTimezoneOffset();
};

/**
 * Convert a string in a database date format to a utc date.
 * Used to compare the current time with the data from the server
 * @example convertDbDateToUtc(2023-12-12 09:00:00, "America/Los_Angeles"); // returns 2023-12-12T17:00:00.000Z -> offset -8
 */
export const convertDbDateToUtc = (dbDate: string, timeZone: string) => {
  const date = new Date(dbDate);
  const timeZoneOffset = getTimezoneOffset(timeZone);
  const localOffset = getLocalOffset();
  const diffOffset = timeZoneOffset - localOffset;
  date.setMinutes(date.getMinutes() + diffOffset);
  return date;
};

export const ISO_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";

export const convertToTimezone = (date: Date, timezone: string) => {
  const formatted = formatInTimeZone(date, timezone, ISO_DATE_FORMAT);
  return new Date(formatted);
};
