import {
  USA_DATE_FORMAT,
  USA_COUNTRY_CODE,
  WORLD_DATE_FORMAT,
} from "./constants";

export const removeTimezone = (date: string): string => {
  return date.slice(0, 19);
};

export const getFormatBasedOnLocale = (countryCode: number): string => {
  if (countryCode === USA_COUNTRY_CODE) {
    return USA_DATE_FORMAT;
  }

  return WORLD_DATE_FORMAT;
};
